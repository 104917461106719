<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'SportBookLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.sportsbook_title')}`,
    titleTemplate: `%s`,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `${locale.getMessage('meta.sportsbook_description')}` },
      { name: 'keyword', content: `${locale.getMessage('meta.sportsbook_keyword')}` },
    ],
    link: [
      {rel: 'canonical', href: window.location.origin + '/sportsbook/'}
    ]
  },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
